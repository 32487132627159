<template>
    <div>
        <v-row>
            <v-col>
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_permisos_create' }">
                    <v-icon class="mr-2">assignment</v-icon>
                    <span class="subtitle-2">Añadir Permiso</span>
                </v-btn>
                <v-btn v-if="$parent.$parent.get_permission('export')" class="ma-1" large solo depressed color="secondary" 
                    @click="$refs.exportar_dialog.show()">
                    <v-icon class="mr-2">file_copy</v-icon>
                    <span class="subtitle-2">Exportar Tabla</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="$parent.$parent.get_permission('show_resumen')">
                <PermisosRendimiento /> 
            </v-col>
            <v-col cols="12"> 
                <DashCard title="Tareas Críticas" body-class="pa-0">
                    <BuscadorTabla ref="buscador" class="px-4 pt-4" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" :estados="estados" />
                    <v-divider />
                    <v-skeleton-loader type="table" :loading="tabla_loading">
                        <v-data-table dense :headers="headers" :items="tabla_items" :search="busqueda" :custom-filter="filtro" :mobile-breakpoint="0">
                            <template v-slot:item.id="{ item }">
                                <router-link :to="{ name: 'dashboard_permisos_item', params: { id: item.id } }">{{ item.id }}</router-link>
                            </template>
                            <template v-slot:item.fecha_creacion="{ item }">
                                {{ item.fecha_creacion | moment("L") }}
                            </template>
                            <template v-slot:item.persona_autor="{ item }">
                                <v-chip dark color="indigo" v-if="!!item.persona_autor">
                                    <v-avatar left>
                                        <v-icon>account_circle</v-icon>
                                    </v-avatar>
                                    {{ item.persona_autor.first_name }} {{ item.persona_autor.last_name }}
                                </v-chip>
                                <template v-else>No asignado</template>
                            </template>
                            <template v-slot:item.propietarios="{ item }">
                                <ul class="pa-0 ma-0" style="list-style-type: none;">
                                    <li v-for="(prop, i) in item.propietarios" :key="i"><b>{{ prop.gerencia ? prop.gerencia.nombre + "," : "" }}</b> {{ prop.persona }}</li>
                                </ul>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <v-chip dark :color="item.estado | color_estado('permiso')">
                                    {{ item.estado | estado("permiso") }}
                                </v-chip>
                            </template>
                            <template v-slot:item.estado_tiempo="{ item }">
                                <v-chip dark :color="item.estado_tiempo | color_estado('tiempo')">
                                    {{ item.estado_tiempo | estado("tiempo") }}
                                </v-chip>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <v-menu offset-y v-if="$parent.$parent.get_permission('read') || $parent.$parent.get_permission('edit') || $parent.$parent.get_permission('delete')">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text fab small color="primary" depressed v-on="on">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item v-if="$parent.$parent.get_permission('read')" :to="{ name: 'dashboard_permisos_item', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>visibility</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Ver</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit')" :to="{ name: 'dashboard_permisos_edit', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit') && $parent.$parent.get_permission('delete')" @click="ask_eliminar(item)">
                                            <v-list-item-icon>
                                                <v-icon>delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <DialogExportarTablaGeneral ref="exportar_dialog" type="permisos" />
        <CommonDialog ref="dialog_delete" @onClose="delete_item" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import PermisosRendimiento from '@/dash/components/PermisosRendimiento'
import DialogExportarTablaGeneral from '@/dash/dialogs/DialogExportarTablaGeneral'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import CommonDialog from '@/shared/components/CommonDialog'
import raw_estados from '@/assets/estados.json'

export default {
    components: {
        DashCard,
        BuscadorTabla,
        PermisosRendimiento,
        DialogExportarTablaGeneral,
        CommonDialog
    },
    data: () => ({
        resumen: {},
        busqueda: "",
        items: null,
        estados: [
            { 
                columna: "estado", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los estados' }, ...raw_estados['permiso']]
            },
            { 
                columna: "estado_tiempo", 
                icon: "av_timer", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los tiempos' }, ...raw_estados['tiempo']] 
            }
        ],
        busqueda_settings: [
            { 
                value: null, 
                text: "Todo",
            },
            { 
                value: "titulo", 
                text: "Título",
            },
            { 
                value: "fecha_creacion", 
                text: "Elaboracion",
            },
            { 
                value: "persona_autor", 
                text: "Coordinador",
            },
            { 
                value: "area", 
                text: "Area",
            }   
        ],
        headers: [
            { text: "ID", value: "id" },
            { text: "Fecha", value: "fecha_creacion" },
            { text: "Título", value: "titulo" },
            { text: "Área", value: "area.nombre" },
            { text: "Propietarios", value: "propietarios" },
            { text: "Coordinador", value: "persona_autor" },
            { text: "Estado", value: "estado" },
            { text: "Tiempo", value: "estado_tiempo" },
            { text: "", value: "acciones", sortable: false }
        ]
    }),
    computed: {
        tabla_loading() {
            return !this.items;
        },
        tabla_items() {
            if(!this.$refs.buscador) return this.items;
            return this.items.filter(x => this.$refs.buscador.filter_estado(x));
        }
    },
    methods: {
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        ask_eliminar(item) {
            this.$refs.dialog_delete.showDialog(messages.titles['question_delete_item'], messages.texts['delete_item'], "yes_no", item);
        },
        delete_item(response, item) {
            if(response == 'yes') {
                axiosHelper.del('permisos/' + item.id + '/')
                    .then(response => {
                        this.$parent.$parent.reloadComponent();
                    });
            }
        }
    },
    created() {
        this.$emit('title', { text: "Tareas Críticas", icon: "inbox" });
    
        axiosHelper.get('permisos/')
            .then(response => this.items = response.data);
    }
}
</script>